import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, Element, Events } from 'react-scroll';
import { graphql } from 'gatsby';

import 'react-aspect-ratio/aspect-ratio.css';
import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import CTAComponent from '../components/cta';
import Title from '../components/typography/Title';

import { Wave1 } from '../components/wave';

const Background = styled.div`
    background-color: transparent;
    margin-bottom: 277px;
    @media screen and (max-width: 767px) {
        margin-bottom: 153px;
    }
`;
const Heading = styled.div`
    margin-left: 64px;
    margin-right: 64px;
    padding-top: 149px;
    user-select: none;
    @media screen and (max-width: 1024px) {
        margin-left: 32px;
        margin-right: 32px;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        padding-top: 108px;
    }
`;
const SubTitle = styled(Element)`
    font-family: 'Renogare-Regular';
    font-size: ${props => (props.child ? '20px' : '36px')};
    line-height: ${props => (props.child ? '32px' : '40px')};
    letter-spacing: ${props => (props.child ? '-0.5px' : '-1.5px')};
    text-align: left;
    margin-bottom: ${props => (props.child ? '5px' : '14px')};
    color: white;
    user-select: none;
    margin-top: ${props => (props.child ? '27px' : '98px')};
    &:first-child {
        margin-top: 0;
    }
    @media screen and (max-width: 767px) {
        font-size: ${props => (props.child ? '16px' : '24px')};
        line-height: ${props => (props.child ? '24px' : '28px')};
        letter-spacing: -0.92px;
        margin-bottom: ${props => (props.child ? '6px' : '14px')};
        margin-top: ${props => (props.child ? '34px' : '60px')};
    }
`;

const ItemContainer = styled.div`
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
`;

const WaveSection = styled.div`
    position: absolute;
    width: 100%;
    margin-top: -30px;
`;
const Content = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 64px;
    margin-right: 64px;
    justify-content: space-between;
    margin-top: 220px;
    @media screen and (max-width: 1024px) {
        margin-left: 32px;
        margin-right: 32px;
        flex-direction: row;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        flex-direction: column;
        margin-top: 166px;
    }
`;
const Context = styled.div`
    width: fit-content;
    margin-right: 10%;
    min-width: 320px;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const ContextItem = styled.div`
    cursor: pointer;
    text-decoration: none;
    transition: all 200ms ease-in-out;
    padding: ${props => (!props.child ? '10px 15px' : '10px 15px 10px 30px')};
    border-left: ${props =>
        props.active
            ? '1px solid rgba(255,255,255,0.7)'
            : '1px solid rgba(255, 255, 255, 0.1)'};
    a {
        color: ${props =>
            props.active ? 'rgba(255,255,255,0.9)' : 'rgba(255,255,255,0.5)'};
        text-decoration: none;
        font-family: 'Neuzeit Grotesk';
        font-size: 15px;
        line-height: 20px;
    }
`;
const Policy = styled.div`
    width: 100%;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const Desc = styled.div`
    font-family: 'Neuzeit Grotesk';
    color: rgba(255, 255, 255, 0.7);
    font-size: 23px;
    line-height: 32px;
    letter-spacing: normal;
    text-align: left;
    a {
        color: #cca352;
        text-decoration: none;
    }
    @media screen and (max-width: 767px) {
        font-size: 17px;
        line-height: 24px;
    }
`;

export default function PlatformPolicyPage({ data }) {
    const { hero, content, book_now, seo_content } = data.butterPage;

    const [current, setCurrent] = useState(content[0].title);
    useEffect(() => {
        Events.scrollEvent.register('begin', function() {
            console.log('begin', arguments);
        });

        Events.scrollEvent.register('end', function() {
            console.log('end', arguments);
        });
        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);
    return (
        <Layout menu="">
            <SEOComponent
                title="Platform Privacy Policy"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Background>
                <Heading>
                    <Title>{hero[0].title}</Title>
                </Heading>
                <WaveSection>
                    <Wave1 />
                </WaveSection>
                <Content>
                    <Context>
                        <ItemContainer>
                            {content.map((item, index) => {
                                return (
                                    <ContextItem
                                        key={item.title}
                                        child={item.child ? 1 : 0}
                                        active={current === item.title}
                                    >
                                        <Link
                                            to={item.slug}
                                            smooth={true}
                                            duration={500}
                                            offset={-120}
                                            onClick={() => {
                                                setCurrent(item.title);
                                            }}
                                        >
                                            {item.title}
                                        </Link>
                                    </ContextItem>
                                );
                            })}
                        </ItemContainer>
                    </Context>
                    <Policy>
                        {content.map((item, index) => {
                            return (
                                <React.Fragment key={item.title}>
                                    <SubTitle
                                        id={item.slug}
                                        child={item.child ? 1 : 0}
                                    >
                                        {item.title}
                                    </SubTitle>
                                    <Desc
                                        dangerouslySetInnerHTML={{
                                            __html: item.content,
                                        }}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </Policy>
                </Content>
            </Background>
            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}

export const platformPolicyPageQuery = graphql`
    query {
        butterPage(slug: { eq: "platform-policy" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
            }
            content {
                title
                slug
                child
                content
            }
            book_now {
                button_title
                title
            }
        }
    }
`;
